/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 21px;
    left: 9px;
    top: 16px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ffffff;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: gray;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 28px;
    width: 28px;
    left: 34% !important;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #ffffff;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 85px !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #F79B30;
    font-size: 1.15em;
    width: 100%;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0px !important;
    margin-top: 8% !important;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }