
.inputOrden{
    border: 2px solid #969393;
    border-radius: 27px;
    width: 100%;
    height: 26px;
    padding-right: 30px;
    color: #969393;
}

.lupa{
    position: absolute;
    top: 6px;
    right: 9px;
}

.inputOrden:focus{
   outline: none;
}
.myUL {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  overflow-y: scroll;
  height: 105px;
}
