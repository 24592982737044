.pedidosPorSurtir header {
  width: 100%;
  text-align: center;
  color: #C9003E;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 3.75px;
  opacity: 1;
  margin-top: 3.2vh;
  margin-bottom: 5.5vh;
}
.selection-cell-header{

      text-align:center;

}
.selection-cell td{

  align-items:center;

}

.OrderListTable table {
    padding: 0.75rem;
    font-family: 'Gilroy SemiBold';
    width: 91.6vw;
    border-collapse: collapse;
}

.pedidosPorSurtir .OrderListTable thead  {
    border: 2px solid #C9003E;
    width: 100px;
    background: #C9003E 0% 0% no-repeat padding-box;
    color: white;
    margin: auto;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 0.35px;
    
}

.pedidosPorSurtir .OrderListTable thead tr {
  border-right: 3px solid #C9003E;
  border-left: 2px solid #C9003E;
  height: 0.2vh;
  font-size: 14px;
} 

.pedidosPorSurtir .OrderListTable thead th {
  border-right: 2px solid white;
  border-left: 0px;
  border-bottom: 2px solid #C9003E;
  padding: 1.3vh;
  text-align: center;
  
}

.pedidosPorSurtir .OrderListTable tbody tr td{
    border-top: 2px solid #C9003E;
    border: 2px solid #C9003E;
    text-align: center;
    padding: 0.5rem;
    color: #707070;
    font-size: 13.5px;
    border-collapse: collapse;
}

.OrderListTable__status{
    color: #FCC742;
}

.OrderListTable__checkLogo{
    text-align: center;
    margin-bottom: 2rem;
}

.resurtirBtn{
  font-size: 18px;
  text-transform: uppercase;
  width: 80%;
  color:#969393;
  background-color: #FFF;
  border-radius: 50px;
  border: solid 1px #969393;
}
.resurtirBtn:hover{
  background-color: #F79B30;
  border:#F79B30
}

/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    height: 17.5px;
    width: 17px;
    background-color: white;
    border-radius: 50% 50%;
    border:1px solid black;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #F79B30;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #707070;
    border: 1px solid #707070;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 3.5px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .btnm{
    border: none;
    background-color:transparent;
    cursor: pointer;
    outline: none;
  }

  button:focus, input[type="button"]:focus {
    outline: none;
}
  
  .btnm:hover .btndetail{
    fill:rgb(255, 140, 0);
    cursor: pointer;
  }

  .btndetail{
    fill:rgba(128, 128, 128, 0.844);
  }


  .selection-cell-header{
    width: 0.4em;
  }