
.containerantojo {
 
    position: relative;
    padding-left: 35px;
    opacity: 0.6;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: auto;
  }
  
  /* Hide the browser's default radio button */
  .containerantojo input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmarkantojo {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ccc;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .containerantojo:hover input ~ .checkmarkantojo {
    background-color: gray;
  }
  
  /* When the radio button is checked, add a blue background */
  .containerantojo input:checked ~ .checkmarkantojo {
    background-color: #F4C266;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmarkantojo:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .containerantojo input:checked ~ .checkmarkantojo:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .containerantojo .checkmarkantojo:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
  .container-register{
    flex: 0 0 50%;
    max-width: 50%;
}
@media screen and (max-width: 687px), (max-height: 325px) {
    .container-register{
        flex: 0 0 90%;
        max-width: 90%;
    }   
}