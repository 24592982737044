

.selection-cell-header {

      text-align:center;

}

.historial-table table {
    padding: 0.75rem;
    font-family: 'Gilroy SemiBold';
    width: 91.6vw;
    border-collapse: collapse;
}
.historial-table > .historial-table thead  {
    border: 2px solid #149748;
    width: 100px;
    background:#149748 0% 0% no-repeat padding-box;
    color: white;
    margin: auto;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.35px;
    
}
.historial-table >.historial-table thead tr {
  border-right: 3px solid #149748;
  border-left: 2px solid  #149748;
  height: 0.2vh;
  font-size: 14px;
} 

.historial-table > .historial-table thead th {
  border-right: 2px solid white;
  border-left: 0px;
  border-bottom: 2px solid #149748;
  padding: 1.3vh;
  
}

.historial-table > .historial-table tbody tr td{
    border-top: 2px solid #149748;
    border: 2px solid #149748;
    text-align: center;
    padding: 0.5rem;
    color: #707070;
    font-size: 13.5px;
    border-collapse: collapse;
}

.historial-table > .historial-table__status{
    color: #149748;
}

.historial-table__checkLogo{
    text-align: center;
    margin-bottom: 2rem;
}


/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 18px;
    height: 17.5px;
    width: 17px;
    background-color: white;
    border-radius: 50% 50%;
    border:1px solid black;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #F79B30;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #707070;
    border: 1px solid #707070;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 3.5px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }







  .container{
    display: flex;
    font-family:Gilroy-Regular;
    color: #939393;
    font-size: 16px
}
.buscarPedido{
    border-right: 2px solid gray;
    
    padding-right: 10px;

   
}
.contPedido{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}
.inpSearch{
    border: 3px solid #969393;
    padding-right: 12%;
    border-radius: 16px;
    width: 95%;
    height: 26px;
}
.lupa{
    position: absolute;
    right: 21px;
    top: 6px;
    font-family:Gilroy-Regular;
    color: #939393;
 
}
.inpSearch:focus{
    outline: none;
}
.contCheck{
    padding: 5px;
    justify-content: center;
    height: 100%;
    align-items: center;
    display: flex;
}
.infoPedido{
    display: flex
}
















.Historial_Pedidos__header{
  font-family:  Gilroy-Black;
  color: #149748;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 3.75px;
  opacity: 1;
  margin-top: 3.2vh;
  margin-bottom: 5.5vh;
}