.selection-cell-header {

    text-align:center;

}

.pedidos-incompleto table {
  padding: 0.75rem;
  font-family: 'Gilroy SemiBold';
  width: 91.6vw;
  border-collapse: collapse;
}
.pedidos-incompleto > .pedidos-incompleto thead  {
  border: 2px solid #6F2690;
  width: 100px;
  background:#6F2690 0% 0% no-repeat padding-box;
  color: white;
  margin: auto;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.35px;
  
}
.pedidos-incompleto >.pedidos-incompleto thead tr {
border-right: 3px solid #6F2690;
border-left: 2px solid  #6F2690;
height: 0.2vh;
font-size: 14px;
} 

.pedidos-incompleto > .pedidos-incompleto thead th {
border-right: 2px solid white;
border-left: 0px;
border-bottom: 2px solid #6F2690;
padding: 1.3vh;

}

.pedidos-incompleto > .pedidos-incompleto tbody tr td{
  border-top: 2px solid #6F2690;
  border: 2px solid #6F2690;
  text-align: center;
  padding: 0.5rem;
  color: #707070;
  font-size: 13.5px;
  border-collapse: collapse;
}

.pedidos-incompleto > .pedidos-incompleto__status{
  color: #6F2690;
}

.pedidos-incompleto__checkLogo{
  text-align: center;
  margin-bottom: 2rem;
}

.Pedidos_Incompleto__header{
    font-family:  Gilroy-Black;
    color: #6F2690;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 3.75px;
    opacity: 1;
    margin-top: 3.2vh;
    margin-bottom: 5.5vh;
}