.banner-reglas-juego{
    width: 100%;
}
.banner-tutorial{
    width: 100%;

}
.img-reglas-juego{
    height: auto;
    width: 100%;
}

.img-tutorial{
    height: auto;
    width: 100%;
}