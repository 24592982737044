
/* Position and sizing of burger button */
.bm-burger-button {
 
    position: fixed;
    width: 36px;
    height: 30px;
    right: 26px;
    top: 20px;
    z-index: 1032 !important;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    top: 0px !important;
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    z-index:1032 !important;
    top: 0px !important;
  }

@media screen and (max-width: 687px), (max-height: 325px) {
  .bm-burger-button {
    display: block;
  }
  .bm-cross-button{
    display:none !important; 
}
    .menu-item--smal{
        font-size: 5.2vw !important;
        color: #ffff;
        text-decoration: none;
        margin-left: 12%;
        height: auto;
        width: 80%;
        text-align: start;
        margin-top: 13%
       
    }
    
    .menu-item--smal:hover{
        font-size: 5vw;
        color: #ffff;
        text-decoration: none;
        border-style: none;
        margin-left: 12%;
    }
    .lineas{
        width: 100%;
        height: 1px;
        padding: 0px;
        margin: 0px;
        pointer-events: none;
        cursor: default;
        text-decoration: none;
    }
    .bm-item-list{
        padding: 0px !important;
    }
    .movil-menu-logo{
        display: block;
        height: 6%;
        width: 100%;
        pointer-events: none;
        cursor: default;
        text-decoration: none;
        outline: none;
        margin-bottom: 12%;
    }
    a:hover{
        text-decoration: transparent
    }
    .social-icon-movil{
        height: 25%;
        width: 17%;
        margin-left: 0%;
            
    }
    
}

