.cont{
    width: 100vw;
    background: #EF842C;
    position: absolute;
    font-family: Gilroy-Black;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    padding: 0.8% 0% 0.8% 0%;

}
