.footer {

    left: 0;
    bottom: 0;
    color:gray;
    width: 100%;
    background: #e3f2fd;
    text-align: center;
    display: flex;
  }
  .footer-der{
    flex: 0 0 50%;
    max-width: 50%;
  }
  .footer-isq{
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media screen and (max-width: 687px), (max-height: 325px) {
    .footer{
      flex-direction: column;
      padding: 10% !important;
    }
    .footer-der{
      flex: 0 0 90%;
      max-width: 90%;
    }
    .footer-isq{
      flex: 0 0 90%;
      max-width: 90%;
    }
   
  }