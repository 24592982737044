.selection-cell-header {

    text-align:center;

}

.seguimiento-paqueterias table {
  padding: 0.75rem;
  font-family: 'Gilroy SemiBold';
  width: 91.6vw;
  border-collapse: collapse;
}
.seguimiento-paqueterias > .seguimiento-paqueterias thead  {
  border: 2px solid #FA306E;
  width: 100px;
  background:#FA306E 0% 0% no-repeat padding-box;
  color: white;
  margin: auto;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.35px;
  
}
.seguimiento-paqueterias >.seguimiento-paqueterias thead tr {
border-right: 3px solid #FA306E;
border-left: 2px solid  #FA306E;
height: 0.2vh;
font-size: 14px;
} 

.seguimiento-paqueterias > .seguimiento-paqueterias thead th {
border-right: 2px solid white;
border-left: 0px;
border-bottom: 2px solid #FA306E;
padding: 1.3vh;

}

.seguimiento-paqueterias > .seguimiento-paqueterias tbody tr td{
  border-top: 2px solid #FA306E;
  border: 2px solid #FA306E;
  text-align: center;
  padding: 0.5rem;
  color: #707070;
  font-size: 13.5px;
  border-collapse: collapse;
}

.seguimiento-paqueterias > .seguimiento-paqueterias__status{
  color: #FA306E;
}

.seguimiento-paqueterias__checkLogo{
  text-align: center;
  margin-bottom: 2rem;
}

.Seguimiento_Paqueterias__header{
    font-family:  Gilroy-Black;
    color: #FA306E;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 3.75px;
    opacity: 1;
    margin-top: 3.2vh;
    margin-bottom: 5.5vh;
}