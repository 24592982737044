.pedidosCancelados header {
  width: 100%;
  text-align: center;
  color: #FEA729;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 3.75px;
  opacity: 1;
  margin-top: 3.2vh;
  margin-bottom: 5.5vh;
}

.pedidosCancelados .OrderListTable thead {
  border: 2px solid #FEA729;
  background: #FEA729 0% 0% no-repeat padding-box;
}

.pedidosCancelados .OrderListTable tbody tr td {
  border-top: 2px solid #FEA729;
  border: 2px solid #FEA729;
}
.pedidosCancelados .OrderListTable thead tr {
  border-right: 3px solid #FEA729;
  border-left: 2px solid #FEA729;
}

.pedidosCancelados .OrderListTable thead th {
  border-bottom: 2px solid #FEA729;
}