
.error-pedidos table {
    padding: 0.75rem;
    font-family: 'Gilroy SemiBold';
    width: 91.6vw;
    border-collapse: collapse;
  }
  .error-pedidos > .error-pedidos thead  {
    border: 2px solid #e30f17;
    width: 100px;
    background:#e30f17 0% 0% no-repeat padding-box;
    color: white;
    margin: auto;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.35px;
    
  }
  .error-pedidos >.error-pedidos thead tr {
  border-right: 3px solid #e30f17;
  border-left: 2px solid  #e30f17;
  height: 0.2vh;
  font-size: 14px;
  } 
  
  .error-pedidos > .error-pedidos thead th {
  border-right: 2px solid white;
  border-left: 0px;
  border-bottom: 2px solid #e30f17;
  padding: 1.3vh;
  
  }
  
  .error-pedidos > .error-pedidos tbody tr td{
    border-top: 2px solid #e30f17;
    border: 2px solid #e30f17;
    text-align: center;
    padding: 0.5rem;
    color: #707070;
    font-size: 13.5px;
    border-collapse: collapse;
  }
  
  .error-pedidos > .error-pedidos__status{
    color: #e30f17;
  }
  
  .error-pedidos__checkLogo{
    text-align: center;
    margin-bottom: 2rem;
  }

.Error_Pedidos__header{
    font-family:  Gilroy-Black;
    color: #e30f17;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 3.75px;
    opacity: 1;
    margin-top: 3.2vh;
    margin-bottom: 5.5vh;
}