

@font-face {
    font-family: LuloCleanOneBold;
    src: url('./Font/Yellow\ Design\ Studio\ -\ Lulo\ Clean\ One\ Bold_0.otf');
  }


  @font-face {
    font-family:  Gilroy-Black;
    src: url('./Font/Radomir\ Tinkov\ -\ Gilroy-Black.otf');
  }

  @font-face {
    font-family:  Gilroy-ExtraBold;
    src: url('./Font/Radomir\ Tinkov\ -\ Gilroy-ExtraBold.otf');
  }
  @font-face {
    font-family:  Avenir;
    src: url('./Font/Avenir/Avenir.ttc');
  }

  @font-face {
    font-family:  Gilroy-Bold;
    src: url('./Font/Radomir\ Tinkov\ -\ Gilroy-Bold.otf');
  }
  @font-face {
    font-family:  Gilroy-SemiBold;
    src: url('./Font/Radomir\ Tinkov\ -\ Gilroy-SemiBold.otf');
  }

.App{

    min-height:100vh;
    min-width: 100vw;

}
.content::-webkit-scrollbar:vertical { display: none}
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
*{
    font-family:  Gilroy-Bold !important;
   
}

.modal-backdrop {
  
    background-color: #F79B30 !important;
}

.content::-webkit-scrollbar-track:horizontal {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    background-color: #ffffff;
    border-radius: 10px;
}
.content::-webkit-scrollbar-thumb{
background: rgba(200,200,200,1)  !important;
}
body::-webkit-scrollbar-thumb{
    background: rgba(200,200,200,1)  !important;
    }
 
.content::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #ffffff;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    background-color: #ffffff;
}
 
body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #ffffff;
}
.MuiMenu-paper {
  max-height: calc(100% - 96px);
  border: 2px solid #F79B30 !important;
  -webkit-overflow-scrolling: touch;
}
.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 8px !important;
}
.MuiListItem-root.Mui-disabled {
  opacity: 1 !important;
  background: #F79B30 !important;
  color: #ffffff !important;
}
.MuiBadge-colorPrimary {
  background-color: #FF0F18 !important;
}
.react-bootstrap-table{
  display: flex;
    justify-content: center;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .App{
        margin-top: 65px;
    }
  
}
.steppe-container{
    border-radius: 3px;
    margin-top: 4.3vh;
    margin-left: 18vw;
    margin-bottom: 7.3vh;
    width: 60%;
    font-family: 'Gilroy SemiBold';
  }

.steppe-container > div {
  justify-content: center;
}
  
  a{
    text-decoration: none;
  }
.notificationTitle {
  color: #FF6900;
  font-size: 15px;
}
.notificationBody {
  text-align: left;
  color: #707070;
  font-size: 14px;
  font-family: "Gilroy-SemiBold" !important;
}
.notificationHeader {
  background-color: #FF6900;
  font-family: "Gilroy-SemiBold" !important;
  font-size: 18px;
  color: #FFFFFF;
  width: 450px;
  height: 44px;
  padding-left: 21PX;
  padding-top: 13px;
}

.newNotification {
  background-color: #ffe4d1 !important;
}