.search{
    width: 300px;
    position: relative;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.search-input{
  width: 100%;
  height: 30px;
  font-size: 18px;
  background-color: #fff;
  padding: 2px 5px 2px 15px;
  color: #6c6c6c;
  border-radius: 50px;
  outline:none;
  border: solid 1px #707070;
  margin-bottom: 3vh;
}
.search-btn img{
    width: 100%;
}
.search-btn{
    background-color: transparent;
    width: 10%;
    padding: 3px 3px;
    margin-left:-45px;
    margin-bottom: 25px;
    border:none;
    outline:none;
    color: #6c6c6c;
    z-index: 10;
}
.scan-btn{
  height: 30px;
  width: 30px;
  background-color: transparent;
  border: none;
  outline:none;
  margin-left: 1.2vw;
  margin-bottom: 25px;
}