
:root{
    --inventory-color:rgb(37, 150, 190);
}
.InventoryTable.selection-cell-header {

    text-align:center;

}
.inventory-balance{
  margin-top: 3.2vh;
  margin-bottom: 5.5vh;
}
.title-inventory{
    text-align: center;
    color:  var(--inventory-color);
    margin-top: 3.2vh;
    margin-bottom: 5.5vh;
}
.title-inventory{
  font-weight: bold;
  font-size: 30px;
  font-family: 'Gilroy Black';
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 3.75px;
}
.InventoryTable table {
  padding: 0.75rem;
  font-family: 'Gilroy SemiBold';
  width: 83.7vw;
  border-collapse: collapse;
  margin-left: 4.5vw;
  margin-right: 5.5vw;
}

.InventoryTable thead  {
  border: 2px solid var(--primary-color);
  width: 100px;
  background: var(--inventory-color) 0% 0% no-repeat padding-box;
  color: white;
  margin: auto;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.35px;
  
}

.InventoryTable thead tr {
border-right: 3px solid  var(--inventory-color);
border-left: 2px solid  var(--inventory-color);
height: 0.2vh;
font-size: 16px;
text-align: center;
} 

.InventoryTable thead th {
border-right: 2px solid white;
border-left: 0px;
/*text-align: center;*/
border-bottom: 2px solid  var(--inventory-color);
padding: 1.3vh;

}

.InventoryTable tbody tr td{
  border-top: 2px solid  var(--inventory-color);
  border: 2px solid  var(--inventory-color);
  text-align: center;
  width: auto;
  padding: 0.5rem;
  color: #707070;
  font-size: 15px;
  border-collapse: collapse;
}

.InventoryTable__checkLogo{
  text-align: center;
  margin-bottom: 2rem;
}


/* The container */
.InventoryTable .container-checkmark {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.InventoryTable .container-checkmark input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.InventoryTable .checkmark {
  position: absolute;
  top: 0;
  left: 18px;
  height: 17.5px;
  width: 17px;
  background-color: white;
  border-radius: 50% 50%;
  border:1px solid black;
}

/* On mouse-over, add a grey background color */
.InventoryTable .container-checkmark:hover input ~ .checkmark {
  background-color: #F79B30;
}

/* When the checkbox is checked, add a blue background */
.InventoryTable .container-checkmark input:checked ~ .checkmark {
  background-color: #707070;
  border: 1px solid #707070;
}

/* Create the checkmark/indicator (hidden when not checked) */
.InventoryTable .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.InventoryTable .container-checkmark input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.InventoryTable .container-checkmark .checkmark:after {
  left: 5px;
  top: 3.5px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}