#wrapper {
    overflow-x: hidden;
 }
#sidebar-wrapper {
  min-height: 100vh;
  width: 5.8vw;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
.nav-link img{
  margin-left: auto;
  margin-right: auto;
}
.nav-link p{
  justify-content: center;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 6rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.b-example-divider {
    width: 3.6rem;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);
    border: solid white;
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bg-light {
  background-color: #F79B30 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #EF842C;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.nav-pills .nav-link {
  border-radius: 0.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imgs{
  opacity:.7;
  cursor: pointer;
}
.imgs:hover{
  opacity:.6;
  cursor: pointer;
}

.nav-link {
  width: 100% !important;
  padding: .5rem 0.2rem !important;
}