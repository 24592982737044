.HomeNutrisa__container {
    background: transparent linear-gradient(335deg, #FF6900 0%, #F79B30 100%) 0% 0% no-repeat padding-box;
    
}

.HomeNutrisa__b-example-divider {
    display: flex;
    padding: 10px;
    align-items: center;
}

.HomeNutrisa__title {
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Gilroy Black';
    letter-spacing: 9.15px;
    font-size: 4vw;
}


.HomeNutrisa__line_vertical {
    height: 500px;
    width: 3px;
    background-color: white;
    border-radius: 99px;
    margin: 0 20px;
}


.HomeNutrisa__google_component {
    text-align: center;
    margin-top: 15em;
}

.HomeNutrisa__box {
    margin-top: 8em;
}

.HomeNutrisa__box_image {
    width: 330px;
    height: 220px;
}

.HomeNutrisa__google_component_img {
    height: auto;
    max-width: 10%
}

@media (max-width: 425px){
    .HomeNutrisa__title {
        font-size: 7vw;
    }
    
    .HomeNutrisa__home_logo{
        width: 12em;
    }
}

@media (max-width: 320px){
    .HomeNutrisa__home_logo{
        width: 10em;
    }

    .HomeNutrisa__title {
        font-size: 8vw;
    }
}

@media (min-width: 320px) and (max-width: 991px){
    .HomeNutrisa__line_vertical{
        height: 3px;
        width: 60%;
        background-color: white;
        border-radius: 99px;
        margin: auto;
        
    }

    .HomeNutrisa__google_component {
        text-align: center;
        margin: 0;
        height: auto;
        max-width: 100%;
    }

    .HomeNutrisa__google_component_img {
        height: auto;
        max-width: 20%
    }

    .HomeNutrisa__box_image {
        display: none;
    }

    .HomeNutrisa__button_login {
        text-align: right;
        letter-spacing: 1.45px;
        color: #797970;
        text-transform: lowercase;
        opacity: 1;
        font-family: 'Gilroy Black';
        
    }
}

.HomeNutrisa__login{
    text-align: center;
    position: relative;
    top: 20%;
    
}

.HomeNutrisa__button_login {
    text-align: center;
    letter-spacing: 3.45px;
    color: #797970;
    text-transform: uppercase;
    opacity: 1;
    font-family: 'Gilroy Black';
    width: 100%;
}