.ClassCancelarPedidos header {
  width: 100%;
  text-align: center;
  color: #550C0C;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 3.75px;
  opacity: 1;
  margin-top: 3.2vh;
  margin-bottom: 5.5vh;
}

.ClassCancelarPedidos .OrderListTable thead {
  border: 2px solid #550C0C;
  background: #550C0C 0% 0% no-repeat padding-box;
}

.ClassCancelarPedidos .OrderListTable tbody tr td {
  border-top: 2px solid #550C0C;
  border: 2px solid #550C0C;
}
.ClassCancelarPedidos .OrderListTable thead tr {
  border-right: 3px solid #550C0C;
  border-left: 2px solid #550C0C;
}

.ClassCancelarPedidos .OrderListTable thead th {
  border-bottom: 2px solid #550C0C;
}