
.stepper-wrapper{
    display: flex;
    justify-content: space-between;

}
.step-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 23%;
    position: relative;
}
.step-img{
    width: 50px;
    height: 50px;
    padding: 3px;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 1,2rem;
}
.step-img:hover{
    cursor: pointer;
}
.step-name{
    margin-top: 5px ;
    text-align: left;
    letter-spacing: 0.35px;
    color: #969393;
    opacity: 1;
}
.step-name-active{
    text-align: left;
    letter-spacing: 0.35px;
    color: #F79B30;
    opacity: 1;
    font-weight: 500;
}
.divider-line{
    height: 2.5px;
    background-color: #d9d9d9;
    position: absolute;
    top: 40%;
    left: 65%;
}
.divider-line-active{
    background-color: #F79B30 !important;
}
.divider-line-2{
    width: 296%;
}
.divider-line-3{
    width: 125%;
}
.divider-line-4{
    width: 85%;
}
.divider-line-5{
    width:60%;
}





 h3 {
	page-break-before: always !important;
}

/* @media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
   */
 
   .my-page{
    font-size:14px;
        width:70mm;
        margin: 1mm
   }
  
 

  ul, img, table,tr,th,thead,br {
	page-break-inside: avoid !important;
}


  @media print {
    @page {
        size: 70mm 200mm !important;
       
        margin: 1mm !important;
        display: inline-block !important;
       
        widows: 1 !important;
        top: 10px !important;
      }

    th{
        text-align: center !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    }
  }

  .spinner{
    position: fixed;
    z-index: 1000;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%;
    transform: translate(-50%, -50%);
}