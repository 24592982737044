 .modal-dialog {
    max-width: 78 !important;
    height: 90vh !important;
}
.my_modal > .modal-content{
    
    width: 80vw !important;
    height: 100% !important;
}

.btnBorder{
    border-radius: 100px;
    font-size: 15px;
    font-family: 'LuloCleanOne-Bold';
    
}


.inputOrden{
    border: 2px solid #969393;
    border-radius: 27px;
    width: 100%;
    height: 26px;
    padding-right: 30px;
}

.lupa{
    position: absolute;
    top: 6px;
    right: 9px;
}

.inputOrden:focus{
   outline: none;
}
.myUL {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  overflow-y: scroll;
  height: 105px;
}
.a {
    border: 1px solid #ddd;
    margin-top: -1px; /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block
  }