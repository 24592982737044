:root{
    --color-text: #939393;
    --primary-color: #F7F7F7;
    --orange-color: #FD6920;
}
.modal{
    display: block !important;
}
.spinner{
    position: fixed;
    z-index: 1000;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-body .boxweight-container .purchaseResume::-webkit-scrollbar-thumb{
background: var(--color-text)  !important;
}
.modal-body .boxweight-container .purchaseResume::-webkit-scrollbar-thumb{
    background: var(--color-text) !important;
    }
 
.modal-body::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #070707;
}
 
.modal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    background-color: #0f0f0f;
}
 
.modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #ffffff;
}
.modal-dialog{
    max-width: 70%;
    overflow-y: initial !important;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90vh;
}
.modal-content{
    border-radius: 0px;
    margin-top: 10px;
    width:80vw;
    max-height: 100%;
}

.modal-header{
    padding: 5px !important;
     height: 40px;
    border-bottom: 2px solid var(--orange-color);
}
.modal-title{
    color: #969393;
    font-size: 18px;
}
.modal-body{
    background-color: var(--primary-color);
    padding: 20px;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    position: relative;
    display: block;
}

.modal-footer{
    background-color: var(--primary-color);
}
/*INFORMATION PRODUCTS STYLES*/
.infoprod{
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    height: fit-content;
    margin-left: 40px;
}
.no-pedido{
    font-family: Gilroy;
    font-size: 16px;
}
.required {
    color: #e30f17;
    height: 0.2vh;
    font-size: 12px;
} 

.products-table{
    border-top: 2px solid #FF6900;
    border-bottom: 2px solid #FF6900;
    font-family: 'Gilroy-Bold';
    font-size: 15px;
    text-align: center;
    color: #FF6900;
    margin-top: 20px;
}

.products-table div{
    padding: 5px 0;
}
.title-prod{
    color: #939393;
    font-size: 16px;
}
.img-product{
    width: 140px;
    height: 200px;
    position: relative;
}
.img-product img{
    max-width: 140px;
    bottom: 0;
    top: 0;
    position: absolute;
    margin: auto;
}
.cuantity-prod{
    letter-spacing: 0.7px;
    color: #939393;
    opacity: 1;
    font-size: 16px;
}
.price-prod{
    text-align: left;
    color: #707070;
    font-size: 16px;
}

.quantity{
    text-align: center;
    left: 0;
    right: 0;
    margin-left: 0;
    font-family: 'Gilroy SemiBold';
    font-size: 30px;
    color: #FF6900;
}
.btn-outline-success, .btn-outline-danger,  .btn-outline-warning {
    margin-bottom: 10px;
    border-radius: 0px;
    height: 3rem;
    width: 15rem;
}

.total-container{
    right: 0px;
    margin-right: 15%;
    position: absolute;
    bottom: 0;
}
.total{
    text-transform: uppercase;
   color: #535353;
}
/*BOXES MODAL STYLES*/
.boxes{
    padding: 30px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.box1,.box2,.box3{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    text-align: center;
}
.sm{
    width: 40%;
}
.md{
    width: 45%;
}
.lg{
    width: 50%;
}
.box1 .sm{
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
    width: 60%;
}
.box1 .md{
    
    margin-top: 12%;
    width: 70%;
}
.box1 .lg{
    margin-top: 2%;
    width: 80%;
}
.box1,.box2,.box3 p{
    color: #777777;
}
/*Control number styles*/
.ctrl {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    background-color: #FFFF;
    border: 2px solid #969393;
    border-radius: 50px;
    font-size: 20px;
    width: 100px;
    
  }
  
  .ctrl__counter {
    position: relative;
    width: 100px;
    height: 30px;
    color: #969393;
    text-align: center;
    overflow: hidden;
  }
  
  .ctrl__counter.is-input .ctrl__counter-num {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
  }
  
  .ctrl__counter.is-input .ctrl__counter-input {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
  }
  
  .ctrl__counter-input {
    width: 50px;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 2;
    box-shadow: none;
    outline: none;
    border: none;
    color: #333C48;
    font-size: 30px;
    line-height: 100px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
  }
  input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
  .ctrl__button {
    width: 50px;
    line-height: 30px;
    text-align: center;
    color: #969393;
    cursor: pointer;
    -webkit-transition: background-color 100ms ease-in;
    transition: background-color 100ms ease-in;
  }
  
  .ctrl__button:hover {
    background-color: #969393;
    color: #FFFF;
    -webkit-transition: background-color 100ms ease-in;
    transition: background-color 100ms ease-in;
  }
  
  .ctrl__button:active {
    background-color: #969393;
    -webkit-transition: background-color 100ms ease-in;
    transition: background-color 100ms ease-in;
  }
  
  .ctrl__button--decrement { border-radius: 10px 0 0 10px; }
  
  .ctrl__button--increment { border-radius: 0 10px 10px 0; }
  /*BoxWeight*/
  .boxweight-container{
    width: 90% !important;
  }
.boxweight-container .inputWeight{
    color: #FD6920;
    text-align: center;
    width: 70px;
    height: auto;
    opacity:inherit;
    border: none;
    background-color: var(--primary-color);
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.inputWeight:hover{
    border-bottom: 4px solid  #FD6920;
}
.tableBox{
    padding: 10%;
    width: 100%;
    align-items: center;
  }
.tableBox td p{
    vertical-align:middle;
}
.btn-success{
    margin-bottom: 10px;
    border-radius: 0px;
    height: 3rem;
    width: 15rem;
    text-transform: uppercase;
}
.boxweight-container tr{
    color: #FF6900;
}
.boxweight-container thead th{
    border-bottom: 2px solid #FF6900 !important;
    border-top: 2px solid #FF6900 !important;
    color: #FD6920;
}
.boxweight-container tbody td{
    border-top: transparent;
    border-bottom: transparent;
}
.boxweight-container  table tbody{
    border-top: transparent;
    border-bottom: transparent;
}
.boxweight-container tbody th{
    border-top: transparent;
    border-bottom: transparent;
}
#Price{
    color: var(--color-text);
}
.num{
    color:var(--orange-color);
}
td .ctrl__counter:hover{
    border-bottom: 2px solid var(--orange-color);
    
}
.close{
    color:#FF6900;
    font-size: 35px;
}

.btnBorder{
    border-radius: 100px;
    font-size: 15px;
    font-family: 'LuloCleanOne-Bold';
    
}

.bottomm{
    background-color: #F7F7F7;
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 15px;
}
.btnCont{
    width: 25%;
    border: 1px solid green;
    color: green;
    padding: 9px;
    font-family: 'LuloCleanOne-Bold';
}
.btnDisabled{
    width: 25%;
    border: 1px solid black;
    color: white;
    padding: 9px;
    font-family: 'LuloCleanOne-Bold';
    background-color: rgb(170, 170, 170);
}

.btnCont:hover{
    background-color: green;
    color: #F7F7F7;
    cursor: pointer;
}
.btnBack{
    position: absolute;
    left: 1px;
    width: 20%;
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: gray;
    cursor: pointer;
    font-family: 'LuloCleanOne-Bold';
}
