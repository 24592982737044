.SM{
    width: 40%;
}

.MD{
    width: 45%;
}

.GD{
    width: 50%;
}

.total-container-cajas{
    text-align: right;
    margin-right: 5%;
    margin-bottom: 1%;
}