.PedidoIncompletoModal__table th,
.PedidoIncompletoModal__table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 2px solid #FF6900;
}

.PedidoIncompletoModal__table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #FF6900;
  color: #FF6900;
  text-transform: uppercase;
  letter-spacing: 0.33px;
  font-size: 0.9em;
  opacity: 1;
  text-align: left;
  font-family: 'Gilroy-Bold';
}

.PedidoIncompletoModal__table tbody + tbody {
  border-top: 2px solid #FF6900;
}

.PedidoIncompletoModal__button {
    border-radius: 0em;
    background-color: #FD6920;
    border-color: #FD6920;
    color: white;
}

.PedidoIncompletoModal__button:hover {
    color: white;
} 

.PedidoIncompletoModal__button:focus, .PedidoIncompletoModal__button.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem white;
}

.PedidoIncompletoModal__button_confirmar {
    border-radius: 0em;
    border-color: #149748;
    color: #149748;
    text-transform: uppercase;
    font-family: 'LuloCleanOne-Bold';
    width: 30%;
    height: 50px;
}

.PedidoIncompletoModal__button_confirmar:hover {
    color: #149748;
}

.PedidoIncompletoModal__price_total{
    color:#C82020;
    text-transform: uppercase;
    letter-spacing: 0.33px;
    margin-left: 1.2em;
    font-family: 'Gilroy ExtraBold';
}

.PedidoIncompletoModal__label_total {
    color: #A7A7A7;
    text-transform: uppercase;
    font-family: 'Gilroy-Bold';
}

.PedidoIncompletoModal__count{
    width: 6em;
    height: 2em;
    border: 2px solid #FF6900;
    margin: auto;
    border-radius: 40% / 100%;
    padding-top: 4px;
    
}

.combomotivo{
    border-radius: 21px;
    font-size: 14px;
    height: 30px;
    padding: 5px;
    padding-right: 16px;
    color: rgb(103, 103, 103);
    margin-bottom: 5%;
    
  }
  .combomotivocancelar{
    border-radius: 21px;
    font-size: 14px;
    height: 30px;
    padding: 5px;
    padding-right: 16px;
    color: rgb(103, 103, 103);
  }
  .textmotivo{
    height: 45px;
    font-size: 14px;
    border-radius: 6px;
    padding: 5px;
    padding-right: 16px;
    color: rgb(103, 103, 103);
    margin-bottom: 5%;
  }
  .motivocontainer{
    display: flex;
    flex-direction: column;
  }


  .combomotivo:focus {outline:0; border: 1.5px solid rgb(240, 157, 2);box-shadow: 0 0 3px rgba(126, 126, 126, 0.748);}
  .textmotivo:focus {outline:0; border: 1.5px solid rgb(240, 157, 2);box-shadow: 0 0 3px rgba(126, 126, 126, 0.748);}
  

.btnBorder{
    border-radius: 100px;
    font-size: 15px;
    font-family: 'LuloCleanOne-Bold';
    
}

.bottomm{
    background-color: #F7F7F7;
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 15px;
}
.btnCont{
    width: 25%;
    border: 1px solid green;
    color: green;
    padding: 9px;
    font-family: 'LuloCleanOne-Bold';
}
.btnDisabled{
    width: 25%;
    border: 1px solid black;
    color: white;
    padding: 9px;
    font-family: 'LuloCleanOne-Bold';
    background-color: rgb(170, 170, 170);
}
.btnCont:hover{
    background-color: green;
    color: #F7F7F7;
    cursor: pointer;
}
.btnBack{
    position: absolute;
    left: 1px;
    width: 20%;
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: gray;
    cursor: pointer;
    font-family: 'LuloCleanOne-Bold';
}


.minusplus{
    cursor: pointer;
}

.spinner{
    position: fixed;
    z-index: 1000;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%;
    transform: translate(-50%, -50%);
}